<template>
  <v-dialog
    v-model="modalReport"
    persistent
    max-width="70vw"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="rounded-lg">
      <v-card-title
        class="primary py-6 white--text d-flex justify-space-between"
      >
        Reporte de emergencia
        <v-btn color="white" rounded outlined @click="hideModalReport()">
          Cerrar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row class="py-6">
            <v-col
              cols="12"
              md="6"
              v-if="!!report && report.answer_call == 'Si'"
            >
              <v-card width="100%" height="100%" outlined class="rounded-lg">
                <v-card-text class="px-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="d-flex flex-row justify-space-between"
                      >
                        <p class="mb-1">¿Se atendió el llamado?</p>
                        <h4 class="mb-2">
                          {{ report.answer_call }}
                        </h4>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <p class="ml-5 mt-5 mb-1">Descripción</p>
                  <h3 class="ml-5 mb-2">
                    {{ report.details_describe }}
                  </h3>
                  <p class="ml-5 mb-1">Domicilio</p>
                  <h3 class="ml-5 mb-2">
                    {{ report.street + " " + report.ext }}
                  </h3>
                  <p class="ml-5 mb-1">Colonia</p>
                  <h3 class="ml-5 mb-2">
                    {{ report.colony }}
                  </h3>
                  <p class="ml-5 mb-1">Descripción del lugar</p>
                  <h3 class="ml-5 mb-2">
                    {{ report.place_description }}
                  </h3>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" v-else>
              <v-card
                outlined
                width="100%"
                height="100%"
                class="
                  d-flex
                  justify-center
                  align-center
                  flex-column
                  rounded-lg
                "
              >
                <v-card-text
                  class="d-flex justify-center align-center flex-column"
                >
                  <v-icon size="50" color="primary">
                    mdi-shield-account
                  </v-icon>
                  <h3 class="text-center primary--text mt-3">
                    El oficial reportó que no atendió la emergencia
                  </h3>
                  <p class="mb-0 mt-3 font-weight-normal">Razón</p>
                  <p class="black--text font-weight-bold">
                    {{ report.no_attendance_reason }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" v-if="!!report && report.witnesses == 'Si'">
              <v-card width="100%" outlined class="rounded-lg">
                <v-card-text class="px-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="d-flex flex-row justify-space-between"
                      >
                        <p class="mb-1">¿Hubo testigos?</p>
                        <h4 class="mb-2">
                          {{ report.witnesses }}
                        </h4>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>

                  <p class="ml-4 mt-5 mb-1">Nombre</p>
                  <h3 class="ml-4 mb-2">
                    {{ report.name_witnesses }}
                  </h3>
                  <p class="ml-4 mb-1">Domicilio</p>
                  <h3 class="ml-4 mb-2">
                    {{ getStreetComputed }}
                  </h3>
                  <p class="ml-4 mb-1">Colonia</p>
                  <h3 class="ml-4 mb-2">
                    {{ report.colony_witnesses }}
                  </h3>
                  <p class="ml-4 mb-1">Edad</p>
                  <h3 class="ml-4 mb-2">
                    {{ report.age_witnesses }}
                  </h3>
                  <p class="ml-4 mb-1">Género</p>
                  <h3 class="ml-4 mb-2">
                    {{
                      report.gender_witnesses == "m" ? "Masculino" : "Femenino"
                    }}
                  </h3>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" v-else>
              <v-card
                outlined
                width="100%"
                height="100%"
                class="
                  d-flex
                  justify-center
                  align-center
                  flex-column
                  rounded-lg
                "
              >
                <v-card-text
                  class="d-flex justify-center align-center flex-column"
                >
                  <v-icon size="50" color="primary">
                    mdi-account-group-outline
                  </v-icon>
                  <h3 class="text-center primary--text mt-3">
                    No hay testigos de la emergencia
                  </h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="!!report && report.some_detainee == 'Si'"
            align="center"
            justify="center"
          >
            <v-col cols="12" md="8">
              <v-card outlined class="rounded-lg">
                <v-card-text class="px-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="d-flex justify-space-between">
                        <p class="mb-1">¿Hubo detenidos?</p>
                        <h4 class="mb-2">
                          {{ report.some_detainee }}
                        </h4>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item class="pt-4">
                    <v-list-item-avatar>
                      <v-img
                        :src="imageServer"
                        v-if="report.image_detainee != null"
                      ></v-img>
                      <v-icon v-else> mdi-account-multiple </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Nombre del detenido
                      </v-list-item-subtitle>
                      <v-list-item-title
                        v-html="report.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Domicilio del detenido
                      </v-list-item-subtitle>
                      <v-list-item-title
                        v-html="
                          report.street_detainee + ' ' + report.ext_detainee
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Colonia del detenido
                      </v-list-item-subtitle>
                      <v-list-item-title
                        v-html="report.colony_detainee"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Edad </v-list-item-subtitle>
                      <v-list-item-title
                        v-html="report.age_detainee"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Género </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.gender == "m" ? "Masculino" : "Femenino" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-2"></v-divider>
                  <v-subheader class="pl-4 mt-6">
                    Características físicas
                  </v-subheader>
                  <v-divider class="my-2"></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Estatura </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.height }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Complexión </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.complexion }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Color de piel
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.skin_color }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Color de ojos
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.eyes }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Cabello </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.hair_type }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Boca </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.mouth }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Orejas </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.ears }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Naríz </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.noise }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-2"></v-divider>
                  <v-subheader class="pl-4 mt-6"> Otros detalles </v-subheader>
                  <v-divider class="my-2"></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Características generales
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.characteristics }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle> Vestimenta </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.cloting_type }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-card outlined width="100%">
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-icon size="50" color="primary">
                  mdi-account-group-outline
                </v-icon>
                <h3 class="text-center primary--text mt-3">
                  No hay detenidos en esta emergencia
                </h3>
              </v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-6">
        <v-btn
          color="primary"
          rounded
          large
          class="px-12"
          @click="hideModalReport()"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
      report: (state) => state.cache.report,
      modalReport: (state) => state.cache.modalReport,
    }),
    skinColorComputed() {
      if (this.data.user.skin_color == "Albina") {
        return "skin_tone_1";
      }
      if (this.data.user.skin_color == "Blanca") {
        return "skin_tone_2";
      }
      if (this.data.user.skin_color == "Caucastica") {
        return "skin_tone_3";
      }
      if (this.data.user.skin_color == "Morena Claro") {
        return "skin_tone_4";
      }
      if (this.data.user.skin_color == "Morena") {
        return "skin_tone_5";
      }
      if (this.data.user.skin_color == "Morena oscura") {
        return "skin_tone_6";
      }
    },
    getStreetComputed() {
      let rep = "";
      rep = this.report.street_witnesses + " " + this.report.ext_witnesses;
      if (!!this.report.int_witnesses) {
        rep += " int " + this.report.int_witnesses;
      }
      return rep;
    },
    imageServer() {
      return this.server + this.report.image_detainee;
    },
  },
  methods: {
    hideModalReport() {
      this.$store.dispatch("cache/setShowModal", false);
    },
  },
};
</script>