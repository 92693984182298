<template>
  <v-container fluid>
    <v-row class="my-5">
      <v-col cols="12" md="10" class="d-flex flex-row align-center">
        <v-icon size="30" color="primary" class="mr-3">mdi-alert</v-icon>
        <h2>Reportes de emergencia</h2>
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="d-flex justify-center justify-md-end align-center"
      >
        <v-btn color="info" rounded @click="getData()">
          <v-icon left>mdi-reload</v-icon>
          Recargar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="search"
          name="search"
          ref="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar por folio de reporte"
          hide-details
          outlined
          clearable
          class="mb-4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          label="Filtrar por estatus"
          :items="statuses"
          v-model="statusSelected"
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          label="Cancelados"
          :items="cancelled"
          v-model="cancelSelected"
          hide-details
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="dataFiltered"
      :items-per-page="20"
      class="elevation-1"
      :custom-filter="filterOnlyCapsText"
      :loading="isLoading"
    >
      <template v-slot:item.name="{ item }">
        <b>{{ item.user.name + " " + item.user.lastname }}</b>
      </template>
      <template v-slot:item.user.phone="{ item }">
        {{ item.user.phone | phone }}
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-chip label outlined small>
          {{ item.created_at | date }}
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <div>
          <v-chip
            :color="getStatusText(item)[1]"
            small
            :outlined="!!item.deleted_at"
            dark
          >
            <v-icon
              left
              x-small
              v-if="
                (item.status == 'Recibido' || item.status == 'No atendido') &&
                item.deleted_at == null
              "
            >
              mdi-alert
            </v-icon>
            {{ getStatusText(item)[0] }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.police="{ item }">
        <div>
          <v-chip small v-if="!!item.police" color="black" label outlined>
            {{ item.police.name + " " + item.police.lastname }}
          </v-chip>
          <v-chip label color="blue-grey" outlined small v-else>
            Sin oficial asignado
          </v-chip>
        </div>
      </template>
      <template v-slot:item.admin="{ item }">
        <div>
          <v-chip small v-if="!!item.admin" color="black" label outlined>
            {{ item.admin.name + " " + item.admin.lastname }}
          </v-chip>
          <v-chip label color="blue-grey" outlined small v-else>
            Sin asignar
          </v-chip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          fab
          dark
          small
          depressed
          color="primary"
          v-on:click="gotDetails(item)"
          v-if="item.deleted_at == null"
        >
          <v-icon dark> mdi-eye </v-icon>
        </v-btn>
        <v-btn
          v-if="item.status == 'Atendido' && item.report != null"
          class="ml-2"
          fab
          dark
          small
          depressed
          color="info darken-1"
          v-on:click="seeReport(item.report)"
        >
          <v-icon dark> mdi-clipboard-file </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <audio
      style="display: none"
      id="notification"
      preload
      src="/notification.mp3"
    />
    <report-modal></report-modal>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import ReportModal from "../components/ReportModal.vue";
let interval = null;
var audioFile = new Audio("/notification.mp3");

export default {
  components: { ReportModal },
  mounted() {
    this.getData();
  },
  data() {
    return {
      selected: null,
      dialogReport: false,
      search: null,
      headers: [
        { text: "Folio", align: "start", sortable: true, value: "id" },
        { text: "Nombre", value: "name" },
        { text: "Teléfono", value: "user.phone" },
        { text: "Fecha del reporte", value: "created_at" },
        { text: "Estatus", value: "status" },
        { text: "Atendido por", value: "police" },
        { text: "Asignado por", value: "admin" },
        { text: "Acciones", value: "actions" },
      ],
      data: [],
      statuses: [
        "Todos",
        "Pendiente",
        "En camino",
        "En el lugar",
        "Atendido",
        "No atendido",
        "Recibido",
      ],
      cancelled: ["Mostrar", "Ocultar"],
      cancelSelected: "Ocultar",
      statusSelected: "Todos",
      isLoading: true,
    };
  },
  methods: {
    gotDetails(item) {
      if (!!item.report && item.report != null) {
        this.$store.dispatch("cache/saveReport", item.report);
      } else {
        this.$store.dispatch("cache/saveReport", item.report);
      }
      this.$router.push({
        name: "Details",
        params: {
          id: item.id,
          status: item.status,
        },
      });
    },
    getData() {
      let formData = new FormData();
      this.isLoading = true;
      this.axios({
        method: "post",
        url: this.server + "/police-reports/list",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + this.user.token,
        },
      }).then((response) => {
        response.data.forEach((element) => {
          element.user.identification_image_64 = null;
          element.user.front_image_64 = null;
          element.user.aditional_image_64 = null;
        });
        console.log(response.data);
        if (response.data.length > this.emergencies.length) {
          audioFile.play();
        }
        this.isLoading = false;
        this.$store.dispatch("emergencies/setEmergencies", response.data);
        if (interval == null) {
          interval = setInterval(() => this.getData(), 40000);
        }
        this.data = response.data;
      });
    },
    getStatusText(item) {
      if (!!item.deleted_at) {
        return ["Cancelado", "red darken-4"];
      } else if (item.status === "Pendiente") {
        return [item.status, "warning"];
      } else if (item.status === "En camino") {
        return [item.status, "info darken-1"];
      } else if (item.status === "Atendido") {
        return [item.status, "success darken-1"];
      } else if (item.status === "Recibido") {
        return [item.status, "error"];
      } else if (item.status === "No atendido") {
        return [item.status, "orange darken-1"];
      } else if (item.status === "En el lugar") {
        return [item.status, "lime lighten-2"];
      }
    },
    seeReport(report) {
      console.log(report);
      // this.$store.dispatch("cache/saveReport", report);
      // this.$store.dispatch("cache/setShowModal", true);
    },
    filterOnlyCapsText(value, search, item) {
      return (
        (value != null &&
          search != null &&
          typeof value === "string" &&
          item.user.name.toLowerCase().includes(search.toLowerCase())) ||
        item.user.lastname.toLowerCase().includes(search.toLowerCase()) ||
        item.id == search
      );
    },
  },
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
      emergencies: (state) => state.emergencies.emergencies,
    }),
    dataFiltered() {
      let search = this.statusSelected == "Todos" ? "" : this.statusSelected;
      if (this.cancelSelected == "Ocultar") {
        return this.data.filter((item) => {
          return item.status.includes(search) && item.deleted_at == null;
        });
      }
      return this.data.filter((item) => {
        return item.status.includes(search);
      });
    },
  },
  beforeDestroy() {
    clearInterval(interval);
    interval = null;
  },
};
</script>